import React from 'react';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {getFormattedAddressTranslation} from '../../../addressUtils';
import {classes} from './ShippingSectionOverride.st.css';

export enum ShippingColumnDataHook {
  root = 'ShippingColumnDataHook.root',
  title = 'ShippingColumnDataHook.title',
  fullName = 'ShippingColumnDataHook.fullName',
  address = 'ShippingColumnDataHook.address',
  phone = 'ShippingColumnDataHook.phone',
  deliveryTime = 'PickupColumnColumnDataHook.deliveryTime',
}

export const ShippingColumn = () => {
  const localeKeys = useLocaleKeys();
  const {address, isEditor, isPreview, deliveryTime} = useControllerProps().thankYouPageStore;
  const {get} = useSettings();
  const {addressLine, addressLine2, city, country, subdivision, zipCode, fullName, phone} = address;

  const getShippingColumnTitle = () => {
    return get(settingsParams.THANK_YOU_PAGE_SHIPPING_ADDRESS_LABEL) ?? localeKeys.thankYouPage.shippingAddress.label();
  };

  const addressData =
    isEditor || isPreview
      ? addressLine2
      : getFormattedAddressTranslation(
          {addressLine1: addressLine, country, addressLine2, city, subdivision, zipCode},
          localeKeys
        );

  return (
    <div data-hook={ShippingColumnDataHook.root}>
      <ShippingSectionText dataHook={ShippingColumnDataHook.title} text={getShippingColumnTitle()} isTitle={true} />
      <ShippingSectionText dataHook={ShippingColumnDataHook.fullName} text={fullName} />
      <ShippingSectionText dataHook={ShippingColumnDataHook.address} text={addressData} />
      <ShippingSectionText dataHook={ShippingColumnDataHook.phone} text={phone} />
      {deliveryTime && (
        <div className={classes.deliveryTime}>
          <ShippingSectionText dataHook={ShippingColumnDataHook.deliveryTime} text={deliveryTime} />
        </div>
      )}
    </div>
  );
};
